import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';

import { config } from '../../../config';
import ServiceInfo from './ServiceInfo';

const AppHeader = () => {
    const navItems = [
        {
            key: 'vehicle-data',
            route: (
                <NavLink to={'/vehicle-data'}>
                    <FormattedMessage id={'Vehicle Data'} />
                </NavLink>
            ),
        },
        {
            key: 'device-cleaning-job',
            route: (
                <NavLink to={'/device-cleaning-job'}>
                    <FormattedMessage id={'Device Cleaning Job'} />
                </NavLink>
            ),
        },
    ];

    const environment = process.env.NODE_ENV === 'production' ? 'production' : 'local';

    const serviceInfoItem = <ServiceInfo />;
    const userMenuItem = <DefaultUserMenu environment={environment} />;

    return (
        <ApplicationHeader
            label={<FormattedMessage id={'MBB-Connector'} />}
            homeRoute={<Link to={config.homeRoute || ''} />}
            navItems={navItems}
            actionBarItems={[serviceInfoItem, userMenuItem]}
        />
    );
};

export default AppHeader;
