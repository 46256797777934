import { Notification, NumberControl, TimePicker } from '@rio-cloud/rio-uikit';
import { Component } from 'react';
import { httpGet, post } from '../services/requestServices';

export const MBB_CONNECTOR_BASE_URL = process.env.REACT_APP_MBB_CONNECTOR_BASE_URL;
export const DEVICE_CLEANING_JOB_CONFIGURATION_URL =
    `${MBB_CONNECTOR_BASE_URL}/admin-ui/device-cleaning-job/configuration`;

const getCurrentTime = () => {
    const getPadded = (value: any) => (value >= 10 ? value : `0${value}`);
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${getPadded(hours)}:${getPadded(minutes)}`;
};

export class DeviceCleaningJobConfiguration extends Component<any, any> {
    initStateData() {
        return {
            intervalOfDay: 30,
            timeValue: '12:12',
        };
    }

    constructor(props: any) {
        super(props);
        this.state = this.initStateData();
        this.onDataSubmit = this.onDataSubmit.bind(this);
    }

    componentDidMount() {
        this.loadConfiguration();
    }

    loadConfiguration() {
        try {
            httpGet(DEVICE_CLEANING_JOB_CONFIGURATION_URL).then((response: any) => {
                if (response.status === 200) {
                    this.setConfiguration(response.body);
                    Notification.success('Success load configuration.');
                } else {
                    Notification.error('Failed load configuration.');
                }
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }
    }

    setConfiguration(configurationData: any) {
        this.setState({ timeValue: configurationData.time_value});
        this.setState({ intervalOfDay: configurationData.interval_of_day});
    }

    onDataSubmit() {
        try {
            const data = {
                user: this.props.user,
                time_value: this.state.timeValue,
                interval_of_day: this.state.intervalOfDay
            };
            post(data, DEVICE_CLEANING_JOB_CONFIGURATION_URL).then((response: any) => {
                if (response.status === 200) {
                    Notification.success('Success save configuration.');
                } else {
                    Notification.error('Failed save configuration.');
                }
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }
    }

    handleUnknownError() {
        Notification.error('Unknown error.');
    }

    render() {
        return (
            <div className='container-fluid fluid-large'>
                <div className='row'>
                    <h3>Device Cleaning Job Configuration</h3>
                </div>
                <div className='row'>
                    <div className='col-sm-3 form-group'>
                        <label>{'Interval of Day'}</label>
                        <NumberControl
                            min={1}
                            max={30}
                            value={this.state.intervalOfDay}
                            unit={<span>{'days'}</span>}
                            onValueChanged={(value: any) => this.setState({intervalOfDay: value})}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className={'col-sm-3'}>
                        <div className={'form-group'}>
                            <label>{'Run Time'}</label>
                            <TimePicker
                                value={this.state.timeValue}
                                onChange={(value: any) => this.setState({timeValue: value})} />
                        </div>
                        <div className={'btn-toolbar'}>
                            <button className={'btn btn-default'}
                                    onClick={() => this.setState({timeValue: getCurrentTime()})}>
                                {'Set current time'}
                            </button>
                            <button className={'btn btn-default'} onClick={() => this.setState({timeValue: ''})}>
                                {'Clear time'}
                            </button>
                        </div>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-xs-12 padding-5'>
                        <button type='button' className={'btn btn-primary float-left btn-icon-right'}
                                onClick={this.onDataSubmit}>
                            <span className='rioglyph rioglyph-chevron-right' aria-hidden='true' />
                            {'Save'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
