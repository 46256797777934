import cloneDeep from 'lodash/fp/cloneDeep';
import merge from 'lodash/fp/merge';
import { accessToken } from '../../../configuration/tokenHandling/accessToken';

const authorizedRequest = (fetch: any) => (url: any, options: any) =>
    fetch(url, merge(cloneDeep(options), {
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    }));

export const post = (data: any, url: any, fetch = window.fetch) => {
    return authorizedRequest(fetch)(
        url,
        {
            method: 'POST',
            body: JSON.stringify(data),
            mode: 'cors',
        }).then((resp: any) => {
        return resp.json().then((body: any) => {
            return Promise.resolve({status: resp.status, message: resp.statusText, body});
        });
    });
};

export const get = (url: any, fetch = window.fetch) =>
    (authorizedRequest(fetch)(url, {method: 'GET'})
        .then((response: any) => response.json()));

export const httpGet = (url: any, fetch = window.fetch) =>
    authorizedRequest(fetch)(url, {method: 'GET'})
        .then((resp: any) => {
            return resp.json().then((body: any) => {
                return Promise.resolve({status: resp.status, message: resp.statusText, body});
            });
        });
