import { Component } from 'react';

import { ClearableInput, Notification } from '@rio-cloud/rio-uikit';
import { httpGet, post } from '../services/requestServices';

export const MBB_CONNECTOR_BASE_URL = process.env.REACT_APP_MBB_CONNECTOR_BASE_URL;
export const SEARCH_VEHICLE_DATA_URL = `${MBB_CONNECTOR_BASE_URL}/vehicle-data/search/vehicle/data/{vin}/{user}`;
export const UNREGISTER_VIN_URL = `${MBB_CONNECTOR_BASE_URL}/vehicle-data/unregister/{vin}/{user}`;
export const SWITCH_MVS_MODE_URL = `${MBB_CONNECTOR_BASE_URL}/vehicle-data/switch-mvsgeo-mode/{user}`;
export const SUBSCRIBE_TSS_KAFKA_URL = `${MBB_CONNECTOR_BASE_URL}/vehicle-data/subscribe/tssKafka/{vin}/{user}`;
export const UNSUBSCRIBE_TSS_KAFKA_URL = `${MBB_CONNECTOR_BASE_URL}/vehicle-data/unsubscribe/tssKafka/{vin}/{user}`;
export const DELETE_RIO_MFH_DEVICE_URL = `${MBB_CONNECTOR_BASE_URL}/vehicle-data/delete-mfh-device/{vin}/{user}`;

export class VehicleData extends Component<any, any> {
    initStateData() {
        return {
            vin: { value: '', isValid: false },
            hide: true,
            disable:false,
            deviceRegistrationStatus: '',
            tssSubscriptionStatus: '',
            mfhDeviceRegistrationStatus: '',
            mvsGeoStatus: '',
        };
    }

    constructor(props: any) {
        super(props);
        this.state = this.initStateData();
        this.onDataSubmit = this.onDataSubmit.bind(this);
        this.onUnregisterVinClick = this.onUnregisterVinClick.bind(this);
        this.onSwitchMvsGeoModeBtnClicked = this.onSwitchMvsGeoModeBtnClicked.bind(this);
        this.onSubscribeTssKafkaClicked = this.onSubscribeTssKafkaClicked.bind(this);
        this.onUnsubscribeTssKafkaClicked = this.onUnsubscribeTssKafkaClicked.bind(this);
        this.deleteRioMfhDeviceBtnClicked = this.deleteRioMfhDeviceBtnClicked.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleUnknownError() {
        Notification.error('Unknown error.');
    }

    onUnregisterVinClick() {
        try {
            httpGet(UNREGISTER_VIN_URL.replace('{vin}', this.state.vin.value).replace('{user}', this.props.user))
                .then((response: any) => {

                    if (response.status === 200) {
                        const unregisterResultModel = response.body;
                        if(unregisterResultModel.is_success) {
                            Notification.success(unregisterResultModel.message);
                        } else {
                            Notification.warning(unregisterResultModel.message);
                        }
                    } else {
                        Notification.error('Error occurred: unregister failed');
                    }
                }).catch ((e: any) => {
                console.error(`Request Failed: ${e}`);
                Notification.error('Request Failed.');
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }
    }

    onSwitchMvsGeoModeBtnClicked(mode: any) {
        try {
            const data = {
                vin: this.state.vin.value,
                mode
            };
            post(data, SWITCH_MVS_MODE_URL.replace('{user}', this.props.user))
                .then((response: any) => {
                    if (response.status === 200) {
                        const switchMvsGeoModeResultModel = response.body;
                        if(switchMvsGeoModeResultModel.is_success) {
                            Notification.success(switchMvsGeoModeResultModel.message);
                        } else {
                            Notification.warning(switchMvsGeoModeResultModel.message);
                        }
                    } else {
                        Notification.error('Switch MVSGeo Mode Failed.');
                    }
                }).catch ((e: any) => {
                Notification.error('Request Failed.');
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }
    }

    onSubscribeTssKafkaClicked() {
        try {
            httpGet(SUBSCRIBE_TSS_KAFKA_URL.replace('{vin}', this.state.vin.value).replace('{user}', this.props.user))
                .then((response: any) => {
                    if (response.status === 200) {
                        const subscribeResultModel = response.body;
                        Notification.success(subscribeResultModel.message);
                    } else {
                        Notification.error('Failed Start Job...');
                    }
                }).catch ((e: any) => {
                Notification.error('Request Failed.');
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }
    }

    onUnsubscribeTssKafkaClicked() {
        try {
            httpGet(UNSUBSCRIBE_TSS_KAFKA_URL.replace('{vin}', this.state.vin.value).replace('{user}', this.props.user))
                .then((response: any) => {
                    if (response.status === 200) {
                        const unsubscribeResultModel = response.body;
                        Notification.success(unsubscribeResultModel.message);
                    } else {
                        Notification.error('Failed Start Job...');
                    }
                }).catch ((e: any)=> {
                Notification.error('Request Failed.');
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }
    }

    onDataSubmit() {
        try {
            httpGet(SEARCH_VEHICLE_DATA_URL.replace('{vin}', this.state.vin.value).replace('{user}', this.props.user))
                .then((response: any) => {

                    this.setState({hide:false});
                    if (response.status === 200) {

                        this.setState({disable:false});
                        this.setConfiguration(response.body);
                        Notification.success('search successfully');

                    } else {
                        this.setState({disable:true});
                        Notification.error('Error occured: search error');
                    }
                }).catch ((e: any) => {
                Notification.error('Request Failed.');
            });
        } catch (e) {
            this.handleUnknownError();
        }

    }

    deleteRioMfhDeviceBtnClicked() {
        try {
            httpGet(DELETE_RIO_MFH_DEVICE_URL.replace('{vin}', this.state.vin.value).replace('{user}', this.props.user))
                .then((response: any) => {

                    if (response.status === 200) {

                        const deleteRioMfhDeviceResultModel = response.body;

                        if(deleteRioMfhDeviceResultModel.is_success) {
                            Notification.success(deleteRioMfhDeviceResultModel.message);
                        } else {
                            Notification.error(deleteRioMfhDeviceResultModel.message);
                        }

                    } else {
                        Notification.error('Error occurred: Failed Delete.');
                    }
                }).catch ((e: any) => {
                console.error(`Request Failed: ${e}`);
                Notification.error('Request Failed.');
            });
        } catch (e) {
            console.error(`unknown error: ${e}`);
            this.handleUnknownError();
        }

    }

    setConfiguration(configurationData: any) {
        this.setState({ deviceRegistrationStatus: configurationData.device_registration_status});
        this.setState({ tssSubscriptionStatus: configurationData.tss_subscription_status});
        this.setState({ mfhDeviceRegistrationStatus: configurationData.mfh_device_registration_status});
        this.setState({ mvsGeoStatus: configurationData.mvs_geo_status});
    }

    handleInputChange(param: any, event: any) {
        const target = event.target;
        const targetType = target.type;
        const targetName = target.name;
        const targetValue = targetType === 'checkbox' ? !this.state[targetName].value : target.value;

        // validate value
        const isOk = this.isValidValue(targetName, targetValue);

        // update local state
        this.setState({
            [targetName]: { value: targetValue, isValid: isOk }
        });
    }

    isValidValue(name: any, value: any) {
        switch (name) {
            case 'vin': // string
                return (value !== null && value.length !==  0 && /^[A-Z0-9]{17}$/.test(value));
            default:
                return false;
        }
    }

    checkNull(){
        const vin =this.state.vin.value;
        if(vin===null || vin.length===0){
            return true;
        }
    }

    renderVinContent() {
        const normalClass = 'padding-0';
        const errorClass = 'padding-0 has-error has-feedback';
        return (
            <div className='row margin-left-10 margin-right-10'>
                <div className='row margin-left-10 margin-right-10'>
                    <div className='col-xs-6 padding-5'>
                        <div className='row margin-left-10 margin-right-10'>
                            <div className='col-xs-12 padding-5'>
                                <div className='row'>
                                    <label className='width-150 float-left'>VIN</label>
                                </div>
                                <div className='row'>
                                    <div className='flex'>
                                        <ClearableInput className={this.state.vin.isValid ? normalClass : errorClass}
                                                        name='vin'
                                                        value={this.state.vin.value}
                                                        onChange={this.handleInputChange}
                                                        autoFocus
                                        />
                                        <button type='button'
                                                className={'btn btn-primary margin-left-10 btn-icon-right '}
                                                disabled={!this.state.vin.isValid}
                                                onClick={this.onDataSubmit}>
                                            <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                            {'Search'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <hr className='border-color-dark border-top-only'/>
                </div>
            </div>
        );
    }

    renderVinSearchResultContent() {
        const deviceRegistrationStatusColor =
            this.state.deviceRegistrationStatus === 'REGISTERED' ? '#5cb85c' : '#d90000';
        const mvsGeoStatusColor = this.state.mvsGeoStatus !== 'OFF' ? '#5cb85c' : '#d90000';
        const tssSubscriptionStatusColor = this.state.tssSubscriptionStatus === 'SUBSCRIBED' ? '#5cb85c' : '#d90000';
        const mfhDeviceRegistrationStatusColor =
            this.state.mfhDeviceRegistrationStatus === 'REGISTERED' ? '#5cb85c' : '#d90000';

        return (
            <div className='row margin-left-10 margin-right-10'>

                <div className='row margin-left-10 margin-right-10'>
                    <div className='col-xs-12 padding-5'>
                        <div className='row margin-left-10 margin-right-10'>
                            <p className='text-color-danger text-bold'>
                                * The action result of click buttons &apos;Unregister,
                                Subscribe, Unsubscribe, Normal mode, OML mode&apos; is not in real time.</p>
                            <p className='text-color-danger text-bold'>
                                Please re-search the VIN 10seconds ~ 2minutes to indicate the final state.</p>
                        </div>
                    </div>
                </div>
                <div className='row margin-left-10 margin-right-10'>
                    <div className='col-xs-12 padding-5'>
                        <div className='row margin-left-10 margin-right-10'>
                            <div className='col-xs-12 padding-5'>
                                <div className='row height-50 flex'>
                                    <label className='width-250 '>Registration status :</label>
                                    <span className='width-150'>
                                        <span color={deviceRegistrationStatusColor}>
                                            {this.state.deviceRegistrationStatus}
                                        </span>
                                    </span>
                                    <button type='button'
                                            className={'btn btn-primary btn-icon-right'}
                                            disabled={this.state.disable}
                                            onClick={this.onUnregisterVinClick}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'Unregister'}
                                    </button>
                                </div>

                                <div className='row height-50 flex'>
                                    <label className='width-250'>MFH Device Registration Status:</label>
                                    <span className='width-150'>
                                        <span color={mfhDeviceRegistrationStatusColor}>
                                            {this.state.mfhDeviceRegistrationStatus}
                                        </span>
                                    </span>
                                    <button type='button'
                                            className={'btn btn-primary  btn-icon-right'}
                                            disabled={this.state.disabled}
                                            onClick={this.deleteRioMfhDeviceBtnClicked}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'Unregister'}
                                    </button>
                                </div>

                                <div className='row height-50 flex'>
                                    <label className='width-250'>TSS-Kafka Subscription Status:</label>
                                    <span className='width-150'>
                                        <span color={tssSubscriptionStatusColor}>
                                            {this.state.tssSubscriptionStatus}
                                        </span>
                                    </span>
                                    <button type='button'
                                            className={'btn btn-primary  btn-icon-right'}
                                            disabled={this.state.disabled}
                                            onClick={this.onUnsubscribeTssKafkaClicked}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'Unsubscribe'}
                                    </button>
                                    <button type='button'
                                            className={'btn btn-primary  btn-icon-right margin-left-10'}
                                            disabled={this.state.disable}
                                            onClick={this.onSubscribeTssKafkaClicked}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'Subscribe'}
                                    </button>
                                </div>

                                <div className='row height-50 flex'>
                                    <label className='width-250'>MVS-geo Status:</label>
                                    <span className='width-150'>
                                        <span color={mvsGeoStatusColor}>{this.state.mvsGeoStatus}</span>
                                    </span>
                                    <button type='button'
                                            className={'btn btn-primary  btn-icon-right'}
                                            disabled={this.state.disable}
                                            onClick={this.onSwitchMvsGeoModeBtnClicked.bind(this,'OFF')}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'Switch off'}
                                    </button>
                                    <button type='button'
                                            className={'btn btn-primary  btn-icon-right margin-left-10'}
                                            disabled={this.state.disable}
                                            onClick={this.onSwitchMvsGeoModeBtnClicked.bind(this,'NORMAL')}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'Normal mode'}
                                    </button>
                                    <button type='button'
                                            className={'btn btn-primary  btn-icon-right  margin-left-10'}
                                            disabled={this.state.disable}
                                            onClick={this.onSwitchMvsGeoModeBtnClicked.bind(this,'OML')}>
                                        <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'/>
                                        {'OML mode'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {hide} = this.state;
        if (hide) {
            return (
                <div className='container-fluid fluid-large'>
                    <div className='row'>
                        {this.renderVinContent()}
                    </div>
                </div>
            );
        }
        return (
            <div className='container-fluid fluid-large'>
                <div className='row'>
                    {this.renderVinContent()}
                </div>
                <div className='row'>
                    {this.renderVinSearchResultContent()}
                </div>
            </div>
        );
    }
}
